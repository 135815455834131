<template>
  <div class="change-pin">
    <top-bar back backroute="security" :title="$app.secure.clean($n.t('15.1'))" />
    <div class="container">
      <c-pin v-if="step === 0" :danger-message="$var('danger')"
             :title="$app.secure.clean($n.t('15.2'))" :value.sync="code1" @complete="
               step = 1 
               $var('danger','')" />
      <c-pin v-if="step === 1" :danger-message="$var('danger')" 
             :title="$app.secure.clean($n.t('15.4'))" :value.sync="code2" @complete="step = 2" />
      <c-pin v-if="step === 2" :danger-message="$var('danger')" 
             :title="$app.secure.clean($n.t('15.5'))" :value.sync="code3" @complete="step = 3" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageIndex',
  data() {
    return {
      code1: '',
      code2: '',
      code3: '',
      step: 0,
      currentPin: localStorage.getItem('pin'),
    }
  },
  watch: {
    step() {
      if (this.step === 1) {
        if (this.code1 !== this.currentPin) {
          this.$var('danger', $app.secure.clean($n.t('20.2')))
          this.step = 0
        }
      }
      if (this.step === 3) {
        this.$var('danger', '')
        if (this.code2 !== this.code3) {
          this.$var('danger', $app.secure.clean($n.t('20.1')))
          this.code2 = ''
          this.code3 = ''
          this.step = 1
        } else {
          this.$router.push({ name: 'profile', })
          localStorage.setItem('pin', this.code3)
        }
      }
      if (this.step === 2) {
        this.$var('danger', '')
      }
    },
  },
  methods: {},
}
</script>

<style scoped lang="scss">
.change-pin {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - (calc(env(safe-area-inset-top) + 70px) + calc(env(safe-area-inset-bottom) + 96px)));

}
</style>
